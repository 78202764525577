<template>
  <div class="w-100 my-5">
    <div class="box-white d-flex flex-column">
      <div class="row w-100 mt-5 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">Reference Id</span>
        <input class="col-sm m-1 font-12" v-model="referenceId" />
      </div>
      <div class="row w-100 mt-5 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">Capture Id</span>
        <input class="col-sm m-1 font-12" v-model="captureId" />
      </div>
      <div class="my-5 d-flex justify-content-end">
        <v-btn class="mx-5 button-normal" small @click="buyTicketTask" :disabled="(!referenceId || !captureId)">
          <span>Buy Ticket by Manually</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { showLoading } from '../../../functions';

export default {
  name: 'BuyTicket',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      referenceId: '',
      captureId: ''
    }
  },
  mounted() {
    if (window.location.hostname !== 'localhost') {
      this.$router.push('/');
    }
  },
  methods: {
    buyTicketTask() {
      if (!this.referenceId) {
        this.$toast.info('Please enter Reference Id.');
        return;
      }
      if (!this.captureId) {
        this.$toast.info('Please enter Capture Id.');
        return;
      }
      const params = {
        functionName: 'doBuyTicket',
        referenceId: this.referenceId,
        captureId: this.captureId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>